
import axios from '../utils/request';
const baseShout = {
    bb: 'https://erp.careld.cn',
    //呼叫中心
    changyongzhishi: '/too/center/commonKnowle/getCommonKnowle',//常用知识
    userInfor: '/too/center/member/getMemberInfoByLocalPhone',//用户信息
    EquipmentId: '/too/center/member/getMemberInfoByEquipmentId',//待我办理用户信息回显
    Wconverse: '/too/center/callCenterHistory/getOutCallHis',//外呼通话记录
    converse: '/too/center/callCenterHistory/getCustomerCallHis',//通话记录
    getFooCallHis: '/too/center/callCenterHistory/getFooCallHis',//400通话记录
    userHistory: '/too/center/customerWorkOrder/getUserHistoryWorkOrderList',//查询用户历史工单
    commission: '/too/center/customerWorkOrder/getCustomerAgentWorkOrderList',//查询代办工单
    getBreatheAgent: '/too/center/customerWorkOrder/getBreatheAgentWorkOrderList',//查询代办工单
    createOrder: '/too/center/customerWorkOrder/insertCustomerWorkOrder',//创建工单
    updateWorkOrder: '/too/center/customerWorkOrder/updateCustomerWorkOrder',//更新工单
    parTitle: '/too/center/parameter/getParameterByTitle',//转接到前台



    //400工单
    fourCommission: '/too/center/hotlineWorkOrder/getAgentWorkOrderList',//查询代办工单
    fourHistory: '/too/center/hotlineWorkOrder/getUserHistoryWorkOrderList',//查询用户历史工单
    createFourOrder: '/too/center/hotlineWorkOrder/insertHotlineWorkOrder',//创建400工单
    search: '/too/center/commonKnowle/getCommonKnowle',//创建400工单
    updateMember: '/too/center/member/updateMemberInfo',//保存生活状况信息
    updates: '/too/center/member/updateMember',//保存用户信息
    getMemberInfos: '/too/center/member/getMemberInfo',//查询康康用户信息
    getMemberInfoById: '/too/center/member/getMemberInfoById',//查询康康用户信息
    eqMem: '/too/center/member/getEquipmentServer',//通过用户ID和设备ID查询服务项
    updateHotOrder: '/too/center/hotlineWorkOrder/updateHotlineWorkOrder',//更新400工单
    getOuterMemberPhone: '/too/center/outerMember/getOuterMemberByPhone',//来电显示用户信息
    insertOuterMembers: '/too/center/outerMember/insertOuterMember',//添加用户信息
    updateOuterMembers: '/too/center/outerMember/updateOuterMember',//更新来电用户信息

    //常用知识
    commonKnowledge: '/too/center/commonKnowle/getCommonKnowleList',//分页查询常用知识
    deleteCommonKnowles: '/too/center/commonKnowle/deleteCommonKnowle',//删除常用知识
    insertKnowle: '/too/center/commonKnowle/insertCommonKnowle',//创建常用知识

    //查询老人信息
    memberOld: '/too/center/member/getMemberList',//分页查老人列表
    memberDetail: '/too/center/member/getMemberDetailById',//查询老人详情
    getCommonKnow: '/too/center/commonKnowleType/getCommonKnowleType',//查询常用知识分类
    CommonKnowleById: '/too/center/commonKnowle/getCommonKnowleById',//查询常用知识详情
    updateCommon: '/too/center/commonKnowle/updateCommonKnowle',//更新常用知识
    searchKnowle: '/too/center/commonKnowle/getCommonKnowle',//模糊搜索
    CommonKnowleTypeList: '/too/center/commonKnowleType/getCommonKnowleTypeList',//分页查询常用知识分类
    insertCommonType: '/too/center/commonKnowleType/insertCommonKnowleType',//创建常用知识分类
    CommonKnowleTypeById: '/too/center/commonKnowleType/getCommonKnowleTypeById',//查询常用知识详情分类
    deleteKnowleType: '/too/center/commonKnowleType/deleteCommonKnowleType',//删除常用知识分类
    updateCommonType: '/too/center/commonKnowleType/updateCommonKnowleType',//更新常用知识分类
    batchUpType: '/too/center/commonKnowle/batchUpdateType',//批量修改分类
    upStatus: '/too/center/customerService/updateStatus',//修改客服状态
    serverOffLine: '/too/center/customerService/offline',//客服离线
    getEquipment: '/too/center/equipment/getEquipmentList',//查询终端管理列表
    insertEqui: '/too/center/equipment/insertEquipment',//新增终端管理列表
    getEquipmentByIds: '/too/center/equipment/getEquipmentById',//查看终端管理详情
    updateEquipment: '/too/center/equipment/updateEquipment',//更改终端管理信息
    // exportEquipment:'/too/center/equipment/exportEquipmentList',//终端管理导出数据
    batIm: '/too/center/equipment/batchImportEquipment',//批量导入设备
    cenHistory: '/too/center/callCenterHistory/getCallCenterHistoryList',//系统查询中的通话记录

    //产品管理
    product: '/too/center/product/getProductList',//查询产品列表
    addProduct: '/too/center/product/insertProduct',//增加产品信息
    deleteProduct: '/too/center/product/deleteProduct',//删除产品信息
    detailProduct: '/too/center/product/getProductById',//查询产品详情
    changeProduct: '/too/center/product/updateProduct',//修改产品信息
    exportProduct: '/too/center/product/exportProductList',//数据导出
    getProType: '/too/center/product/getProductType',//查询产品下拉筛选项

    //手机应用
    getAppUser: '/too/center/appuser/getAppUserList',//app用户管理列表数据
    updateState: '/too/center/appuser/updateUseState',//app更改用户启用状态
    getFamily: '/too/center/appfamily/getFamilyList',//家庭管理列表数据
    updateFamilyBy: '/too/center/appfamily/updateFamilyById',//修改家庭管理列表数据
    getFamilyUser: '/too/center/appfamily/getFamilyUserList',//查询家庭id
    getFamilyEquipment: '/too/center/appfamily/getFamilyEquipmentList',//查询家庭设备
    getFamilyPhoto: '/too/center/appfamily/getFamilyPhotoList',//查询家庭相册
    deletePhoto: '/too/center/appfamily/deleteFamilyPhoto',//删除家庭相册
    updateAdmin: '/too/center/appfamily/updateFamilyAdmin',//修改家庭管理员
    updateUrgent: '/too/center/appfamily/updateFamilyUrgent',//修改家庭联系人
    deleteUser: '/too/center/appfamily/deleteFamilyUser',//删除家庭成员
    unbindEquipment: '/too/center/appfamily/unbindFamilyEquipment',//解绑家庭设备
    getstics: '/too/center/appstatistics/getStatistics',//app数据统计
    //手机应用中的广告管理
    phoneAdvert: '/too/center/advert/getAdvertList',//分页查询列表
    appAdvert: '/too/center/advert/getAppAdvertById',//查询广告详情
    interAdvert: '/too/center/advert/insertAppAdvert',//增加广告
    upAdvert: '/too/center/advert/updateAppAdvert',//更新广告
    //帮助反馈
    help: '/too/center/help/getHelpList',//分页查询帮助反馈
    addHelp: '/too/center/help/insertAppHelp',//增加
    searchHelp: '/too/center/help/getAppHelpById',//查询帮助反馈详情
    upHelp: '/too/center/help/updateAppHelp',//更新
    deleteHelp: '/too/center/help/deleteAppHelp',//删除
    upConLine: '/too/center/help/updateConsumerHotline',//设置联系电话
    //定时消息推送
    messageList: '/too/center/timedMessage/getTimedMessageList',//查询定时消息列表
    interMaeesge: '/too/center/timedMessage/insertTimedMessage',//增加
    dingProduct: '/too/center/product/getProduct',//产品分类
    timeDing: '/too/center/timedMessage/getTimedMessageById',//查询定时消息详情
    upTimeMess: '/too/center/timedMessage/updateTimedMessage',//修改定时消息
    upTimeMessSta: '/too/center/timedMessage/updateTimedMessageStatus',//修改定时消息
    his: '/too/center/alarmHelpHis/getAllHisList',//报警信息查询列表
    alaPro: '/too/center/product/getAlarmProduct',//报警信息查询的产品名


    isShout: '/coo/record/customer/getCallInPhone',//获取是否分配有来电呼叫接口
    busy: '/coo/record/customer/callOutPhone',//客服接听外呼、置忙状态接口
    callHistory: '/coo/record/customer/getPhoneCallId',//1.5.获取电话通话记录ID接口
    serviceKanban: '/coo/record/customer/callStatistics',//客服接听外呼、置忙状态接口
    endReason: '/coo/record/customer/getEndReason',//1.6.获取电话通话挂断方接口
    backInfor: '/coo/record/customer/outbound',// 外呼中心往外拨打电话的时候先调一下这个接口
    beiti: '/coo/record/customer/initcno',//判断被踢下线
    cStatistic: '/coo/record/customer/callStatistics',//客服看板
    //客服看板
    getHisList: '/too/center/alarmHelpHis/getHisList',//分页查询紧急求助历史记录
    getWorkList: '/too/center/workorder/getWorkOrderList',//分页查询工单统计记录历史记录

    //参数配置
    getArlarmConfs: '/too/center/arlarm/getArlarmConf',//根据类型查询告警配置
    updateSwitch: '/too/center/arlarm/updateSwitchConf',//修改告警开关
    updateThreshold: '/too/center/arlarm/updateThresholdConf',//修改告警阈值
    updateFunction: '/too/center/arlarm/updateFunctionConf',//修改功能参数
    updateDateFunctionConfs: '/too/center/arlarm/updateDateFunctionConf',//修改功能参数时间
    // 远程协助
    memByFamily: '/too/center/member/getMemberByFamily',//搜索家庭使用人信息
    famUserMid: '/too/center/appfamily/getFamilyUsersByMid',// 根据使用人查询所在家庭用户列表
    upFamAdminCno: '/too/center/appfamily/updateFamilyAdminByCno',//客服修改家庭管理员
    upFamUrgCno: '/too/center/appfamily/updateFamilyUrgentByCno',//客服修改家庭紧急联系人:
    leaveMess: '/too/center/appfamily/leaveMessage',//家庭成员留言:
    deleteInvite: '/too/center/appfamily/deleteFamilyInviteByCno',//删除家庭邀请待加入成员: 
    deleteFamCno: '/too/center/appfamily/deleteFamilyUserByCno ',//客服删除家庭成员: 
    insertUFam: '/too/center/appuser/inviteUserFamily',//邀请家庭成员APP用户: 
    insertMem: '/too/center/member/insertMemberByCno',//添加设备使用人：
    bindEquipMem: '/too/center/member/getMemberHealthCno',//查询设备绑定使用人信息： 
    upMemYMBCno: '/too/center/member/updateMemberYMByCno',//开通设备使用人远盟服务： 
    upMemByCno: '/too/center/member/updateMemberByCno',//客服更新设备使用人信息：
    memberHeal: '/too/center/health/getMemberHealth',//查询老人健康数据: 
    inMemHealth: '/too/center/health/insertMemberHealth',//添加老人健康数据: 
    memEquip: '/too/center/equipment/getMemberEquipment',//查询老人绑定设备信息: 
    menFamId: '/too/center/member/getMemberByFamilyId',//查询家庭使用人信息：
    bindEquCno: '/too/center/equipment/bindEquipmentByCno',//客服绑定设备使用人:
    equIMEI: '/too/center/equipment/getEquipmentByIMEI',//根据IMIE查询设备信息:  
    upNotice: '/too/center/equipment/updateNoticeSet',//修改设备异常通知设置:  
    workType: '/too/center/workOrderType/getWorkOrderType',//工单类别

    //远程协助
    getRemindMemberId: '/too/center/remind/getRemindByMemberId',//查询用户用药计划
    getRemindId: '/too/center/remind/getRemindById',//查询用药计划详情
    getDrugName: '/too/center/remind/getDrugByName',//根据名称查询药品
    insertRemind: '/too/center/remind/insertMemberRemind',//创建使用者用药计划
    updateRemind: '/too/center/remind/updateMemberRemind',//更新使用者用药计划
    getLeaveMessage: '/too/center/leaveMessage/getLeaveMessage',//查询提醒留言
    insertLeaveMessage: '/too/center/leaveMessage/insertLeaveMessage',//添加提醒留言
    updateLeaveMessage: '/too/center/leaveMessage/updateLeaveMessage',//更新提醒留言
    getLeaveMessageEquipment: '/too/center/equipment/getLeaveMessageEquipment',//查询使用者提醒留言设备信息
    timedOne: '/too/center/timedMessage/getTimedMessageByEquipmentId',//查询设备一周内定时消息
    resendM: '/too/center/timedMessage/resendTimedMessage',//重发定时消息
    resendLeave: '/too/center/leaveMessage/resendLeaveMessage',//重发留言提醒
    canREL: '/too/center/leaveMessage/cancelResendLeaveMessage',//取消
    addFaUa: '/too/center/member/addFaUaMaEpByCno',//设备使用者全新设备绑定

    //h5销售分组
    getGroups: '/too/center/salesgroup/getGroup',//查询销售分组字典
    //销售分组管理
    SaGroup: '/too/center/salesgroup/getGroupList',
    inGroup: '/too/center/salesgroup/insertGroup',//增加销售分组管理
    exGroupL: '/too/center/salesgroup/exportGroupList',//导出销售分组
    upGroup: '/too/center/salesgroup/updateGroup',//修改销售分组
    updateGroups: '/too/center/salesgroup/changeEquipmentGroup',//修改设备销售分组状态
    updateBindGroup: '/too/center/salesgroup/changeBindEquipmentGroup',//h5处理绑定数据
    getGroupConfs: '/too/center/groupconf/getGroupConf',//查询销售分组配置
    updateGroupConfs: '/too/center/groupconf/updateGroupConf',//修改销售分组配置
    exHistory: "/too/center/callCenterHistory/exportCallCenterHistoryList",//导出通话记录
    statistics: "/too/center/customerService/statistics",//查询客服统计数据
    fourSta: '/too/center/customerService/statistics',//系统查询400记录
    winList: '/too/center/window/getWindowList',//分页查询弹窗信息
    inWindow: '/too/center/window/insertWindow',//新增弹窗信息
    searchWin: '/too/center/window/getWindow',//查询弹窗信息
    upWindow: '/too/center/window/updateWindow',//修改弹窗信息
    deleteWin: '/too/center/window/delWindow',//删除弹窗信息
    App: '/too/center/help/getAppSet',//获取App链接
    inApp: '/too/center/help/insertAppSet',//增加App链接
    upApp: '/too/center/help/updateAppSet',//修改App链接
    callloss: '/too/center/customerService/getCallLoss',//客服呼损
    lossOrder: '/too/center/customerWorkOrder/insertCallBackWorkOrder',//呼损的创建工单
    equipmentPhone: '/too/center/equipment/checkPhone',//检查设备手机号
    updateLocalPhone: '/too/center/equipment/updateLocalPhone',//更换设备本地号码
    shopList: '/too/center/order/getShopOrderList',//付费管理
    serPay: '/too/center/order/getServer',//查询产品所属服务
    getExchangeCodes: '/too/center/wechat/getExchangeCodeList',//查看兑换码
    cancelCode: '/too/center/wechat/cancelCode',//作废兑换码
    getActivityDict: '/too/center/wechat/getActivityDict',//查看活动字典
    moList: '/too/center/mobile/getMobileList',//分页查询号卡信息
    upMobile: '/too/center/mobile/updateMobile',//号卡管理更新数据
    serWh: '/too/center/mobile/selWhiteList',//号卡管理查询白名单
    upWhite: '/too/center/mobile/setWhiteList',//修改白名单
    grMargin: '/too/center/mobile/getGroupMargin',//号卡管理流量查询
    upMoList: '/too/center/mobile/updateMobileList',//号卡管理更新当页数据
    radio: '/too/center/radio/getRadioList',//查询电台列表
    adRadio: '/too/center/radio/addRadio',//添加电台
    upRadio: '/too/center/radio/updateRadio',//修改电台信息
    searchInfo: '/too/center/radio/getRadioInfo',//查询电台详情
    deleteRa: '/too/center/radio/deleteRadio',//删除电台信息
    que: '/too/center/ymuser/getUserList',//救援信息查询
    opSer: '/eoo/wechat/pay/openServer',//付费管理强制发货
    //AI播报
    getAiBroadcast: '/too/center/broadcast/getAiBroadcast',//分页查询AI播报
    EquipmentAiBroadcast: '/too/center/broadcast/updateEquipmentAiBroadcast',//更新AI播报
    getAiBroadcastConf: '/too/center/broadcast/getAiBroadcastConf',//查询AI播报配置
    insertAiBroadcastConf: '/too/center/broadcast/insertAiBroadcastConf',//增加AI播报配置
    deleteAiBroadcastConf: '/too/center/broadcast/deleteAiBroadcastConf',//删除AI播报配置
    updateAiBroadcastConf: '/too/center/broadcast/updateAiBroadcastConf',//修改AI播报配置
    repeatEquipmentRecord: 'too/center/salesgroup/repeatEquipmentRecord',//录入imei绑定设备
    repeatEquipment: '/too/center/salesgroup/repeatEquipment',//录入imei立即生效
    replace: '/too/center/eqpmtreplace/getReplaceInfo',//客服中心查询换机信息
    exReplay: '/too/center/eqpmtreplace/excReplace',//客服中心确定换机
    cusOrder: "/too//center/customerWorkOrder/getWorkOrderList",//工单查询列表
    hand: '/too/center/customerHandleLog/getHandleLog',//工单查询操作记录
    addRepository: '/too/center/rbtrepository/addRepository',//添加分类
    getRepositoryAll: '/too/center/rbtrepository/getRepositoryAll',//查询所有分类
    delRepository: '/too/center/rbtrepository/delRepository',//删除分类
    updateRepository: '/too/center/rbtrepository/updateRepository',//修改分类
    getAnswerList: '/too/center/rbtanswer/getAnswerList',//查询应答列表
    getRepositoryList: '/too/center/rbtrepository/getRepositoryList',//查询分类列表
    addAnswer: '/too/center/rbtanswer/addAnswer',//添加应答
    delAnswer: '/too/center/rbtanswer/delAnswer',//删除应答
    updateAnswer: '/too/center/rbtanswer/updateAnswer',//修改应答
    addSniff: '/too/center/rbtsniff/addSniff',//添加嗅探词
    delSniff: '/too/center/rbtsniff/delSniff',//删除嗅探词
    getSniffAll: '/too/center/rbtsniff/getSniffAll',//查询所有嗅探词
    getSniffList: '/too/center/rbtsniff/getSniffList',//查询嗅探词列表
    getSniffVoiceRecordList: '/too/center/rbtsniff/getSniffVoiceRecordList',//查询嗅探词列表
    delAnswerBatch: '/too/center/rbtanswer/delAnswerBatch',//应答批量删除分类
    delRepositoryBatch: '/too/center/rbtrepository/delRepositoryBatch',//批量删除分类
    addRepositoryAnswer: '/too/center/rbtanswer/addRepositoryAnswer',//添加分类应答
    delSniffBatch: '/too/center/rbtsniff/delSniffBatch',//批量删除嗅探词
    familyBy: '/too/center/appuser/getFamilyByUser',
    skill: '/too/center/skill/getSkillList',//技能管理列表
    staSkill: '/too/center/skill/updateSkill',//技能开关
    noConf: '/too/center/equipment/getNoticeConf',//查询设备通知设置
    upNoConf: '/too/center/equipment/updateNoticeConf',//修改设备通知设置
    addRisk: '/too/center/risk/addRisk',//标记为风控设备（异常）
    delRisk: '/too/center/risk/delRisk',//删除设备风控标记(恢复)
    noLi: '/too/center/alarmnotice/getNoticeList',
    famUse: '/too/center/appfamily/getFamilyUsersByFid',
    weather: '/too/center/equipment/delayWeather',//客服中心天气预报
    getMenuList: '/too/center/rbtmenu/getMenuList',//机器人首页菜单列表查询
    updateMenu: '/too/center/rbtmenu/updateMenu',//机器人首页菜单修改
    MenugetList: '/too/center/weblog/getList',//查询日志记录
    lun: '/too/center/carousel/getList',//查询轮播图列表
    addLun: '/too/center/carousel/addChart',//添加轮播图
    updateLun: '/too/center/carousel/updateChart',//修改轮播图
    deLun: '/too/center/carousel/deleteChart',//删除轮播图
    alaList: '/too/center/alarmapi/getUserList',//告警API查询列表
    addAla: '/too/center/alarmapi/addUser',//告警API添加
    upAla: '/too/center/alarmapi/updateUser',//告警API修改
    knowList: '/too/center/knowledge/getKnowledgeList',//养生知识查询
    typeList: '/too/center/knowledgeType/getTypeList',//查询知识分类
    inKnow: '/too/center/knowledge/insertKnowledge',//增加养生知识
    upKnow: '/too/center/knowledge/updateKnowledge',//修改养生知识
    deKnow: '/too/center/knowledge/deleteKnowledge',//删除养生知识
    getType: '/too/center/knowledgeType/getTypeList',//查询养生知识分类
    deType: '/too/center/knowledgeType/deleteType',//删除养生知识分类
    inType: '/too/center/knowledgeType/insertType',//增加养生知识分类
    upType: '/too/center/knowledgeType/updateType',//修改养生知识分类
    autoLogin: '/too/center/autoLogin/getAutoLoginList',//查询自动登录
    insertLogin: '/too/center/autoLogin/insertAutoLogin',//添加自动登录
    updateLogin: '/too/center/autoLogin/updateAutoLogin',//修改自动登录
    deleteLogin: '/too/center/autoLogin/deleteAutoLogin',//删除自动登录
    crdmediGetList: '/too/center/crdmedicalpush/getList',//查询推送配置列表
    getCrdCompany: '/too/center/crdmedicalpush/getCrdCompany',//获取企业
    crdmediAddInfo: '/too/center/crdmedicalpush/addInfo',//添加推送配置
    crdmediUpdateInfo: '/too/center/crdmedicalpush/updateInfo',//修改推送配置
    crdmediDeleteInfo: '/too/center/crdmedicalpush/deleteInfo',//删除推送配置
    getTestCase: '/too/center/crdmedicalpush/getTestCase',//获取测试用
    crdList: '/too/center/crdcompany/getList',//查询企业账户
    billList: '/too/center/crdbill/getList',//查询账单记录列表 
    billDetail: '/too/center/crdbill/getDetail',//查询账单记录详情 
    recharge: '/too/center/rechargeRecod/getRechargeRecodList',// 分页查询充值记录
    reConfirm: '/too/center/rechargeRecod/confirmRechargeRecod',//充值记录确认 
    reOrder: '/too/center/rechargeRecod/getCrdRechargeOrderWechat',//查询微信支付
    reTrans: '/too/center/rechargeRecod/getCrdRechargeOrderTransfer',//查询对公转账 
    upUnit: '/too/center/crdcompany/updateUnit',//修改企业单价 
    outHis: '/too/center/outbound/getEquipmentHisList',//查看出库设备记录
    outList: '/too/center/outbound/getHisList',//分页查询出库记录 
    houseMan: '/too/user/getHouseManager',//查询仓库管理员 
    hisBy: '/too/center/outbound/getEquipmentHisByImei',//查看设备出库记录 
    openService: '/too/center/equipment/getOpenService',//查询设备服务详情 
    updateGroupNum: '/too/center/groupconf/updateGroupConfOtherServer',//修改销售分组直连配置 

    addCrdmail: '/too/center/crdmailnotice/addInfo',//添加企业站内信息列表
    getCrdMail: '/too/center/crdmailnotice/getAll',//查询企业站内信息列表
    setTranPhone:'/too/center/customerService/setTranPhone',//转接号码
    cancelTranPhone:'/too/center/customerService/cancelTranPhone',//取消转接
    getEquipmentSta:'/too/center/statistics/getEquipmentSta',//查询设备统计信息
    getPlatformUserSta:'/too/center/statistics/getPlatformUserSta',//查询平台用户
    getProductSta:'/too/center/statistics/getProductSta',//查询设备产品分类统计信息
    getCallOutSta:'/too/center/statistics/getCallOutSta',//查询通话呼出统计
    getCallInSta:'/too/center/statistics/getCallInSta',//查询通话呼入统计
    getShortSta:'/too/center/statistics/getShortSta',//查询短信统计
    getEquipmentGroupSta:'/too/center/statistics/getEquipmentGroupSta',//查询设备分组统计信息
    getCallShortStaDay:'/too/center/statistics/getCallShortStaDay',//查询通话短信统计(日、周、月)
}
//请求方法
export const apiShout = {
    getCrdMail() {
        return axios.post(baseShout.getCrdMail);
    },
    addCrdmail(params) {
        return axios.post(baseShout.addCrdmail, params);
    },
    updateGroupNum(params) {
        return axios.post(baseShout.updateGroupNum, params);
    },
    openService(params) {
        return axios.post(baseShout.openService, params);
    },
    hisBy(params) {
        return axios.post(baseShout.hisBy, params);
    },
    houseMan() {
        return axios.post(baseShout.houseMan);
    },
    outList(params) {
        return axios.post(baseShout.outList, params);
    },
    outHis(params) {
        return axios.post(baseShout.outHis, params);
    },
    upUnit(params) {
        return axios.post(baseShout.upUnit, params);
    },
    reTrans(params) {
        return axios.post(baseShout.reTrans, params);
    },
    reOrder(params) {
        return axios.post(baseShout.reOrder, params);
    },
    reConfirm(params) {
        return axios.post(baseShout.reConfirm, params);
    },
    // 分页查询充值记录
    recharge(params) {
        return axios.post(baseShout.recharge, params);
    },
    //查询账单记录详情 
    billDetail(params) {
        return axios.post(baseShout.billDetail, params);
    },
    //查询账单记录列表
    billList(params) {
        return axios.post(baseShout.billList, params);
    },
    //查询企业账户
    crdList(params) {
        return axios.post(baseShout.crdList, params);
    },
    //查询推送配置列表
    crdmediGetList(params) {
        return axios.post(baseShout.crdmediGetList, params)
    },
    //获取企业
    getCrdCompany() {
        return axios.post(baseShout.getCrdCompany)
    },
    //添加推送配置
    crdmediAddInfo(params) {
        return axios.post(baseShout.crdmediAddInfo, params)
    },
    //修改推送配置
    crdmediUpdateInfo(params) {
        return axios.post(baseShout.crdmediUpdateInfo, params)
    },
    //删除推送配置
    crdmediDeleteInfo(params) {
        return axios.post(baseShout.crdmediDeleteInfo, params)
    },
    deleteLogin(params) {
        return axios.post(baseShout.deleteLogin, params);
    },
    updateLogin(params) {
        return axios.post(baseShout.updateLogin, params);
    },
    insertLogin(params) {
        return axios.post(baseShout.insertLogin, params);
    },
    autoLogin(params) {
        return axios.post(baseShout.autoLogin, params);
    },
    upType(params) {
        return axios.post(baseShout.upType, params);
    },
    inType(params) {
        return axios.post(baseShout.inType, params);
    },
    deType(params) {
        return axios.post(baseShout.deType, params);
    },
    getType() {
        return axios.post(baseShout.getType);
    },
    deKnow(params) {
        return axios.post(baseShout.deKnow, params);
    },
    upKnow(params) {
        return axios.post(baseShout.upKnow, params);
    },
    inKnow(params) {
        return axios.post(baseShout.inKnow, params);
    },
    typeList() {
        return axios.post(baseShout.typeList);
    },
    knowList(params) {
        return axios.post(baseShout.knowList, params);
    },
    upAla(params) {
        return axios.post(baseShout.upAla, params);
    },
    getaddAla(params) {
        return axios.post(baseShout.addAla, params);
    },
    getalaList(params) {
        return axios.post(baseShout.alaList, params);
    },
    getdeLun(params) {
        return axios.post(baseShout.deLun, params);
    },
    getupdateLun(params) {
        return axios.post(baseShout.updateLun, params);
    },
    getaddLun(params) {
        return axios.post(baseShout.addLun, params);
    },
    getlun(params) {
        return axios.post(baseShout.lun, params);
    },
    getweather(params) {
        return axios.post(baseShout.weather, params);
    },
    getfamUse(params) {
        return axios.post(baseShout.famUse, params);
    },
    getnoLi(params) {
        return axios.post(baseShout.noLi, params);
    },
    getupNoConf(params) {
        return axios.post(baseShout.upNoConf, params);
    },
    getnoConf(params) {
        return axios.post(baseShout.noConf, params);
    },
    getstaSkill(params) {
        return axios.post(baseShout.staSkill, params);
    },
    getskill() {
        return axios.post(baseShout.skill);
    },
    getfamilyBy(params) {
        return axios.post(baseShout.familyBy, params);
    },
    gethand(params) {
        return axios.post(baseShout.hand, params);
    },
    getcusOrder(params) {
        return axios.post(baseShout.cusOrder, params);
    },
    getexReplay(params) {
        return axios.post(baseShout.exReplay, params);
    },
    getreplace(params) {
        return axios.post(baseShout.replace, params);
    },
    getalaPro() {
        return axios.post(baseShout.alaPro);
    },
    gethis(params) {
        return axios.post(baseShout.his, params);
    },
    getque(params) {
        return axios.post(baseShout.que, params);
    },
    getdeleteRa(params) {
        return axios.post(baseShout.deleteRa, params);
    },
    getsearchInfo(params) {
        return axios.post(baseShout.searchInfo, params);
    },
    getupRadio(params) {
        return axios.post(baseShout.upRadio, params);
    },
    getadRadio(params) {
        return axios.post(baseShout.adRadio, params);
    },
    getradio(params) {
        return axios.post(baseShout.radio, params);
    },
    getupMoList(params) {
        return axios.post(baseShout.upMoList, params);
    },
    getgrMargin() {
        return axios.post(baseShout.grMargin);
    },
    getupWhite(params) {
        return axios.post(baseShout.upWhite, params);
    },
    getserWh(params) {
        return axios.post(baseShout.serWh, params);
    },
    getupMobile(params) {
        return axios.post(baseShout.upMobile, params);
    },
    getmoList(params) {
        return axios.post(baseShout.moList, params);
    },
    getopSer(params) {
        return axios.post(baseShout.opSer, params);
    },
    getserPay(params) {
        return axios.post(baseShout.serPay, params);
    },
    getshopList(params) {
        return axios.post(baseShout.shopList, params);
    },
    geteqMem(params) {
        return axios.post(baseShout.eqMem, params);
    },
    getaddFaUa(params) {
        return axios.post(baseShout.addFaUa, params);
    },
    getlossOrder(params) {
        return axios.post(baseShout.lossOrder, params);
    },
    getcallloss(params) {
        return axios.post(baseShout.callloss, params);
    },
    getupApp(params) {
        return axios.post(baseShout.upApp, params);
    },
    getApp() {
        return axios.post(baseShout.App);
    },
    getinApp(params) {
        return axios.post(baseShout.inApp, params);
    },
    getdeleteWin(params) {
        return axios.post(baseShout.deleteWin, params);
    },
    getupWindow(params) {
        return axios.post(baseShout.upWindow, params);
    },
    getsearchWin(params) {
        return axios.post(baseShout.searchWin, params);
    },
    getinWindow(params) {
        return axios.post(baseShout.inWindow, params);
    },
    getwinList(params) {
        return axios.post(baseShout.winList, params);
    },
    getfourSta(params) {
        return axios.post(baseShout.fourSta, params);
    },
    getexHistory(query) {
        return axios.get(baseShout.exHistory, { responseType: "blob" }, { params: query });
    },
    getcanREL(params) {
        return axios.post(baseShout.canREL, params);
    },
    getresendLeave(params) {
        return axios.post(baseShout.resendLeave, params);
    },
    getresendM(params) {
        return axios.post(baseShout.resendM, params);
    },
    gettimedOne(params) {
        return axios.post(baseShout.timedOne, params);
    },
    //销售分组管理
    getSaGroup(params) {
        return axios.post(baseShout.SaGroup, params);
    },
    getinGroup(params) {
        return axios.post(baseShout.inGroup, params);
    },
    getexGroupL(query) {
        return axios.get(baseShout.exGroupL, { responseType: "blob" }, { params: query });
    },
    getupGroup(params) {
        return axios.post(baseShout.upGroup, params);
    },
    //修改定时消息的状态
    getupTimeMessSta(params) {
        return axios.post(baseShout.upTimeMessSta, params);
    },
    //工单类别
    getworkType() {
        return axios.post(baseShout.workType);
    },
    //修改设备异常通知设置
    getupNotice(params) {
        return axios.post(baseShout.upNotice, params);
    },
    //根据IMIE查询设备信息
    getequIMEI(params) {
        return axios.post(baseShout.equIMEI, params);
    },
    //客服绑定设备使用人
    getbindEquCno(params) {
        return axios.post(baseShout.bindEquCno, params);
    },
    //查询家庭使用人信息
    getmenFamId(params) {
        return axios.post(baseShout.menFamId, params);
    },
    //查询老人绑定设备信息
    getmemEquip(params) {
        return axios.post(baseShout.memEquip, params);
    },
    //添加老人健康数据
    getinMemHealth(params) {
        return axios.post(baseShout.inMemHealth, params);
    },
    //查询老人健康数据:
    getmemberHeal(params) {
        return axios.post(baseShout.memberHeal, params);
    },
    //客服更新设备使用人信息
    getupMemByCno(params) {
        return axios.post(baseShout.upMemByCno, params);
    },
    //开通设备使用人远盟服务
    getupMemYMBCno(params) {
        return axios.post(baseShout.upMemYMBCno, params);
    },
    //查询设备绑定使用人信息
    getbindEquipMem(params) {
        return axios.post(baseShout.bindEquipMem, params);
    },
    //添加设备使用人
    getinsertMem(params) {
        return axios.post(baseShout.insertMem, params);
    },
    //添加家庭成员APP用户:
    getinsertUFam(params) {
        return axios.post(baseShout.insertUFam, params);
    },
    //客服删除家庭成员
    getdeleteFamCno(paramss) {
        return axios.post(baseShout.deleteFamCno, paramss);
    },
    //删除家庭邀请待加入成员:
    getdeleteInvite(params) {
        return axios.post(baseShout.deleteInvite, params);
    },
    //家庭成员留言:
    getleaveMess(params) {
        return axios.post(baseShout.leaveMess, params);
    },
    //客服修改家庭紧急联系人
    getupFamUrgCno(params) {
        return axios.post(baseShout.upFamUrgCno, params);
    },
    //客服修改家庭管理员
    getupFamAdminCno(params) {
        return axios.post(baseShout.upFamAdminCno, params);
    },
    //搜索家庭使用人信息
    getmemByFamily(params) {
        return axios.post(baseShout.memByFamily, params);
    },
    //根据使用人查询所在家庭用户列表
    getfamUserMid(params) {
        return axios.post(baseShout.famUserMid, params);
    },
    //客服看板
    getcStatistic(params) {
        return axios.post(baseShout.cStatistic, params);
    },
    //转接
    getparTitle(params) {
        return axios.post(baseShout.parTitle, params);
    },
    //系统查询中的通话记录
    getcenHistory(params) {
        return axios.post(baseShout.cenHistory, params);
    },
    getbatIm(params) {
        return axios.post(baseShout.batIm, params);
    },
    //定时消息推送
    getMessageList(params) {
        return axios.post(baseShout.messageList, params);
    },
    //增加定时消息推送
    getInterMaeesge(params) {
        return axios.post(baseShout.interMaeesge, params);
    },
    //产品分类
    getDingProduct() {
        return axios.post(baseShout.dingProduct);
    },
    //查询定时消息详情
    getTimeDing(params) {
        return axios.post(baseShout.timeDing, params);
    },
    //修改定时消息
    getUpTimeMess(params) {
        return axios.post(baseShout.upTimeMess, params);
    },
    //被踢下线
    getBeiti(params) {
        return axios.post(baseShout.beiti, params);
    },
    //帮助反馈
    getupConLine(params) {
        return axios.post(baseShout.upConLine, params);
    },
    getHelp(params) {
        return axios.post(baseShout.help, params);
    },
    getAddHelp(params) {
        return axios.post(baseShout.addHelp, params);
    },
    getSearchHelp(params) {
        return axios.post(baseShout.searchHelp, params);
    },
    getUpHelp(params) {
        return axios.post(baseShout.upHelp, params);
    },
    getDeleteHelp(params) {
        return axios.post(baseShout.deleteHelp, params);
    },
    //手机应用查询用户列表
    getPhoneAdvert(params) {
        return axios.post(baseShout.phoneAdvert, params);
    },
    //手机应用查询广告详情
    getAppAdvert(params) {
        return axios.post(baseShout.appAdvert, params);
    },
    getInterAdvert(params) {
        return axios.post(baseShout.interAdvert, params);
    },
    getUpAdvert(params) {
        return axios.post(baseShout.upAdvert, params);
    },
    //修改客服状态
    getUpStatus(params) {
        return axios.post(baseShout.upStatus, params);
    },
    //客服离线
    getServerOffLine(params) {
        return axios.post(baseShout.serverOffLine, params);
    },
    // 呼叫中心
    // 常用知识
    getKnowledge(params) {
        return axios.post(baseShout.changyongzhishi, params);
    },
    // 用户信息
    getuserInfor(params) {
        return axios.post(baseShout.userInfor, params);
    },
    //通话记录
    getConverse(params) {
        return axios.post(baseShout.converse, params);
    },
    Wconverse(params) {
        return axios.post(baseShout.Wconverse, params)
    },
    getFooCallHis(params) {
        return axios.post(baseShout.getFooCallHis, params)
    },
    //查询用户历史工单
    getUserHistory(params) {
        return axios.post(baseShout.userHistory, params);
    },
    //获取是否分配有来电呼叫接口
    getIsShout(params) {
        return axios.post(baseShout.isShout, params);
    },
    //获取电话通话挂断方接口
    getEndReason(params) {
        return axios.post(baseShout.endReason, params);
    },
    //外呼中心外呼之前调用的接口
    getbackInfor(params) {
        return axios.post(baseShout.backInfor, params);
    },
    //客服接听外呼、置忙状态接口
    getBusy(params) {
        return axios.post(baseShout.busy, params);
    },
    //获取电话通话记录
    getcallHistory(params) {
        return axios.post(baseShout.callHistory, params);
    },
    //查询代办工单
    getCommission(params) {
        return axios.post(baseShout.commission, params);
    },
    //查询外呼代办工单
    getBreatheAgents(params) {
        return axios.post(baseShout.getBreatheAgent, params);
    },
    //创建工单
    getCreateOrder(params) {
        return axios.post(baseShout.createOrder, params);
    },
    //更新工单
    getUpdateWorkOrder(params) {
        return axios.post(baseShout.updateWorkOrder, params);
    },
    //400  查询代办工单
    getFourCommission(params) {
        return axios.post(baseShout.fourCommission, params);
    },
    // 400  查询用户历史工单
    getFourHistory(params) {
        return axios.post(baseShout.fourHistory, params);
    },
    //创建400工单
    getCreateFourOrder(params) {
        return axios.post(baseShout.createFourOrder, params);
    },
    //400更新工单
    getUpdateHotOrder(params) {
        return axios.post(baseShout.updateHotOrder, params);
    },
    //客服看板
    services(params) {
        return axios.post(baseShout.serviceKanban, params)
    },
    //常用知识
    getCommonKnowledge(params) {
        return axios.post(baseShout.commonKnowledge, params);
    },
    //常用知识分类
    CommonKnowleTypeLists(params) {
        return axios.post(baseShout.CommonKnowleTypeList, params)
    },
    //创建常用知识
    getInsertKnowle(params) {
        return axios.post(baseShout.insertKnowle, params);
    },
    getSearch(params) {
        return axios.post(baseShout.search, params)

    },
    //查询老人信息
    getMemberOld(params) {
        return axios.post(baseShout.memberOld, params);
    },
    getMemberDetail(params) {
        return axios.post(baseShout.memberDetail, params);
    },
    deleteCommon(params) {
        return axios.post(baseShout.deleteCommonKnowles, params)
    },
    getCommonKnows() {
        return axios.post(baseShout.getCommonKnow)
    },
    CommonKnowleByIds(params) {
        return axios.post(baseShout.CommonKnowleById, params)
    },
    updateCommons(params) {
        return axios.post(baseShout.updateCommon, params)
    },
    searchKnowles(params) {
        return axios.post(baseShout.searchKnowle, params)
    },
    insertCommonType(params) {
        return axios.post(baseShout.insertCommonType, params)
    },
    CommonKnowleTypeByIds(params) {
        return axios.post(baseShout.CommonKnowleTypeById, params)
    },
    deleteKnowleTypes(params) {
        return axios.post(baseShout.deleteKnowleType, params)
    },
    updateCommonTypes(params) {
        return axios.post(baseShout.updateCommonType, params)
    },
    batchUpType(params) {
        return axios.post(baseShout.batchUpType, params)
    },
    // 待我办理用户信息回显
    ByEquipmentId(params) {
        return axios.post(baseShout.EquipmentId, params)
    },
    //产品管理  查询产品列表
    getProduct(params) {
        return axios.post(baseShout.product, params);
    },
    //增加产品信息
    getAddProduct(params) {
        return axios.post(baseShout.addProduct, params);
    },
    //修改产品信息
    getChangeProduct(params) {
        return axios.post(baseShout.changeProduct, params);
    },
    //删除产品信息
    getDeleteProduct(params) {
        return axios.post(baseShout.deleteProduct, params);
    },
    //查询产品详情
    getDetailProduct(params) {
        return axios.post(baseShout.detailProduct, params);
    },
    //数据导出
    getExportProduct(params) {
        return axios.post(baseShout.exportProduct, params);
    },
    //查询终端管理列表
    getEquipments(params) {
        return axios.post(baseShout.getEquipment, params)
    },
    //新增终端管理列表
    insertEquis(params) {
        return axios.post(baseShout.insertEqui, params)
    },
    //查看终端管理详情
    getEquipmentByIds(params) {
        return axios.post(baseShout.getEquipmentByIds, params)
    },
    //更改终端管理信息
    updateEquipment(params) {
        return axios.post(baseShout.updateEquipment, params)
    },
    // //终端管理导出数据
    // exportEquipments(query){
    //     debugger
    //     console.log(query,"导出数据接口出查看值")
    //     return axios.get('/too/center/equipment/exportEquipmentList',{responseType:'blob'},{data:query})
    // },
    fourCommissions(params) {
        return axios.post(baseShout.fourCommission, params)
    },
    //保存生活状况信息
    updateMembers(params) {
        return axios.post(baseShout.updateMember, params)
    },
    //保存用户信息
    updates(params) {
        return axios.post(baseShout.updates, params)
    },
    //查询康康用户信息
    getMemberInfos(params) {
        return axios.post(baseShout.getMemberInfos, params)
    },
    //根据id查询用户信息
    getMemberInfoByIds(params) {
        return axios.post(baseShout.getMemberInfoById, params)
    },
    //app用户管理列表数据
    getAppUsers(params) {
        return axios.post(baseShout.getAppUser, params)
    },
    //app更改用户启用状态
    updateStates(params) {
        return axios.post(baseShout.updateState, params)
    },
    //家庭管理列表数据
    getFamilys(params) {
        return axios.post(baseShout.getFamily, params)
    },
    //修改家庭管理列表数据
    updateFamilyBys(params) {
        return axios.post(baseShout.updateFamilyBy, params)
    },
    //查询家庭成员
    getFamilyUsers(params) {
        return axios.post(baseShout.getFamilyUser, params)
    },
    //查询家庭设备
    getFamilyEquipments(params) {
        return axios.post(baseShout.getFamilyEquipment, params)
    },
    //查询家庭相册
    getFamilyPhotos(params) {
        return axios.post(baseShout.getFamilyPhoto, params)
    },
    //删除家庭相册
    deletePhotos(params) {
        return axios.post(baseShout.deletePhoto, params)
    },
    //修改家庭管理员
    updateAdmins(params) {
        return axios.post(baseShout.updateAdmin, params)
    },
    //修改家庭联系人
    updateUrgents(params) {
        return axios.post(baseShout.updateUrgent, params)
    },
    //删除家庭成员
    deleteUsers(params) {
        return axios.post(baseShout.deleteUser, params)
    },
    //解绑家庭设备
    unbindEquipments(params) {
        return axios.post(baseShout.unbindEquipment, params)
    },
    //app数据统计
    getsticss(params) {
        return axios.post(baseShout.getstics, params)
    },
    //400来电显示用户信息
    getOuterMemberPhones(params) {
        return axios.post(baseShout.getOuterMemberPhone, params)
    },
    //400添加用户信息
    insertOuterMembers(params) {
        return axios.post(baseShout.insertOuterMembers, params)
    },
    //400更新来电用户信息
    updateOuterMembers(params) {
        return axios.post(baseShout.updateOuterMembers, params)
    },
    //根据类型查询告警配置
    getArlarmConfs(params) {
        return axios.post(baseShout.getArlarmConfs, params)
    },
    //修改告警开关
    updateSwitchs(params) {
        return axios.post(baseShout.updateSwitch, params)
    },
    //修改告警阈值
    updateThresholds(params) {
        return axios.post(baseShout.updateThreshold, params)
    },
    //修改功能参数
    updateFunctions(params) {
        return axios.post(baseShout.updateFunction, params)
    },
    updateDateFunctionConfs(params) {
        return axios.post(baseShout.updateDateFunctionConfs, params)
    },
    //查询用户用药计划
    getRemindMemberIds(params) {
        return axios.post(baseShout.getRemindMemberId, params)
    },
    //查询用药计划详情
    getRemindIds(params) {
        return axios.post(baseShout.getRemindId, params)
    },
    //根据名称查询药品
    getDrugNames(params) {
        return axios.post(baseShout.getDrugName, params)
    },
    //创建使用者用药计划
    insertReminds(params) {
        return axios.post(baseShout.insertRemind, params)
    },
    //更新使用者用药计划
    updateReminds(params) {
        return axios.post(baseShout.updateRemind, params)
    },
    //查询提醒留言
    getLeaveMessages(params) {
        return axios.post(baseShout.getLeaveMessage, params)
    },
    //添加提醒留言
    insertLeaveMessages(params) {
        return axios.post(baseShout.insertLeaveMessage, params)
    },
    //更新提醒留言
    updateLeaveMessages(params) {
        return axios.post(baseShout.updateLeaveMessage, params)
    },
    //查询使用者提醒留言设备信息
    getLeaveMessageEquipments(params) {
        return axios.post(baseShout.getLeaveMessageEquipment, params)
    },
    //h5查询销售分组字典
    getGroups(params) {
        return axios.post(baseShout.getGroups, params)
    },
    //h5修改设备销售分组状态
    updateGroup(params) {
        return axios.post(baseShout.updateGroups, params)
    },
    //h5处理绑定数据
    updateBindGroups(params) {
        return axios.post(baseShout.updateBindGroup, params)
    },
    //查询销售分组配置
    getGroupConfs(params) {
        return axios.post(baseShout.getGroupConfs, params)
    },
    //修改销售分组配置
    updateGroupConfs(params) {
        return axios.post(baseShout.updateGroupConfs, params)
    },
    //查询产品下拉筛选项
    getProTypes(params) {
        return axios.post(baseShout.getProType, params)
    },
    //分页查询紧急求助历史记录
    getHisLists(params) {
        return axios.post(baseShout.getHisList, params)
    },
    getWorkLists(params) {
        return axios.post(baseShout.getWorkList, params)
    },
    //查询客服统计数据
    statistics(params) {
        return axios.post(baseShout.statistics, params)
    },
    //检查手机号码
    equipmentPhone(params) {
        return axios.post(baseShout.equipmentPhone, params)
    },
    //更换设备本地号码
    updateLocalPhone(params) {
        return axios.post(baseShout.updateLocalPhone, params)
    },
    //查询兑换码
    getExchangeCodes(params) {
        return axios.post(baseShout.getExchangeCodes, params)
    },
    //作废兑换码
    cancelCode(params) {
        return axios.post(baseShout.cancelCode, params)
    },
    //查看活动字典
    getActivityDict(params) {
        return axios.post(baseShout.getActivityDict, params)
    },
    //分页查询AI播报
    getAiBroadcast(params) {
        return axios.post(baseShout.getAiBroadcast, params)
    },
    //更新AI播报
    EquipmentAiBroadcast(params) {
        return axios.post(baseShout.EquipmentAiBroadcast, params)
    },
    //查询AI播报配置
    getAiBroadcastConf(params) {
        return axios.post(baseShout.getAiBroadcastConf, params)
    },
    //增加AI播报配置
    insertAiBroadcastConf(params) {
        return axios.post(baseShout.insertAiBroadcastConf, params)
    },
    //删除AI播报配置
    deleteAiBroadcastConf(params) {
        return axios.post(baseShout.deleteAiBroadcastConf, params)
    },
    //修改AI播报配置
    updateAiBroadcastConf(params) {
        return axios.post(baseShout.updateAiBroadcastConf, params)
    },
    //录入imei绑定设备
    repeatEquipmentRecord(params) {
        return axios.post(baseShout.repeatEquipmentRecord, params)
    },
    //录入imei立即生效
    repeatEquipment(params) {
        return axios.post(baseShout.repeatEquipment, params)
    },
    //添加分类
    addRepository(params) {
        return axios.post(baseShout.addRepository, params)
    },
    //查询所有分类
    getRepositoryAll() {
        return axios.post(baseShout.getRepositoryAll)
    },
    //删除分类
    delRepository(params) {
        return axios.post(baseShout.delRepository, params)
    },
    //修改分类
    updateRepository(params) {
        return axios.post(baseShout.updateRepository, params)
    },
    //查询应答列表
    getAnswerList(params) {
        return axios.post(baseShout.getAnswerList, params)
    },
    //添加应答
    addAnswer(params) {
        return axios.post(baseShout.addAnswer, params)
    },
    //查询分类列表
    getRepositoryList(params) {
        return axios.post(baseShout.getRepositoryList, params)
    },
    //删除应答
    delAnswer(params) {
        return axios.post(baseShout.delAnswer, params)
    },
    //修改应答
    updateAnswer(params) {
        return axios.post(baseShout.updateAnswer, params)
    },
    //添加嗅探词
    addSniff(params) {
        return axios.post(baseShout.addSniff, params)
    },
    //删除嗅探词
    delSniff(params) {
        return axios.post(baseShout.delSniff, params)
    },
    //查询所有嗅探词
    getSniffAll(params) {
        return axios.post(baseShout.getSniffAll, params)
    },
    //查询嗅探词列表
    getSniffList(params) {
        return axios.post(baseShout.getSniffList, params)
    },
    //查询嗅探词列表
    getSniffVoiceRecordList(params) {
        return axios.post(baseShout.getSniffVoiceRecordList, params)
    },
    //应答批量删除分类
    delAnswerBatch(params) {
        return axios.post(baseShout.delAnswerBatch, params)
    },
    //知识库批量删除分类
    delRepositoryBatch(params) {
        return axios.post(baseShout.delRepositoryBatch, params)
    },
    //添加分类应答
    addRepositoryAnswer(params) {
        return axios.post(baseShout.addRepositoryAnswer, params)
    },
    //批量删除嗅探词
    delSniffBatch(params) {
        return axios.post(baseShout.delSniffBatch, params)
    },
    //标记为风控设备（异常）
    addRisk(params) {
        return axios.post(baseShout.addRisk, params)
    },
    //删除设备风控标记(恢复)
    delRisk(params) {
        return axios.post(baseShout.delRisk, params)
    },
    //机器人首页菜单列表查询
    getMenuList(params) {
        return axios.post(baseShout.getMenuList, params)
    },
    //机器人首页菜单修改
    updateMenu(params) {
        return axios.post(baseShout.updateMenu, params)
    },
    //查询日志记录
    MenugetList(params) {
        return axios.post(baseShout.MenugetList, params)
    },
    //获取测试用例的接口
    getTestCase(params) {
        return axios.post(baseShout.getTestCase, params)
    },
    //查询设备统计信息
    getEquipmentSta(params) {
        return axios.post(baseShout.getEquipmentSta, params)
    },
    //查询平台用户
    getPlatformUserSta(params) {
        return axios.post(baseShout.getPlatformUserSta, params)
    },
    //查询设备产品分类统计信息
    getProductSta(params) {
        return axios.post(baseShout.getProductSta, params)
    },
    //查询通话呼出统计
    getCallOutSta(params) {
        return axios.post(baseShout.getCallOutSta, params)
    },
    //查询通话呼入统计
    getCallInSta(params) {
        return axios.post(baseShout.getCallInSta, params)
    },
    //查询短信统计
    getShortSta(params) {
        return axios.post(baseShout.getShortSta, params)
    },
    //查询设备分组统计信息
    getEquipmentGroupSta(params) {
        return axios.post(baseShout.getEquipmentGroupSta, params)
    },
    //查询通话短信统计(日、周、月)
    getCallShortStaDay(params) {
        return axios.post(baseShout.getCallShortStaDay, params)
    },
    //号码转接
    setTranPhone(params) {
        return axios.post(baseShout.setTranPhone, params)
    },
    //取消转接
    cancelTranPhone(params) {
        return axios.post(baseShout.cancelTranPhone, params)
    },
}
export default baseShout